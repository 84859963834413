// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/parentweb/v1/parentweb.proto" (package "sparx.science.parentweb.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { Package } from '../../packages/v1/package';
/**
 * @generated from protobuf message sparx.science.parentweb.v1.GetSummaryRequest
 */
export interface GetSummaryRequest {}
/**
 * @generated from protobuf message sparx.science.parentweb.v1.GetSummaryResponse
 */
export interface GetSummaryResponse {
  /**
   * List of recent packages for the user in the subject of the request.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.Package packages = 1;
   */
  packages: Package[];
  /**
   * Date the student last logged in.
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_login = 2;
   */
  lastLogin?: Timestamp;
}
/**
 * @generated from protobuf message sparx.science.parentweb.v1.GetEmailSummaryRequest
 */
export interface GetEmailSummaryRequest {
  /**
   * Student name in the form "students/{student_id}".
   *
   * @generated from protobuf field: string student_name = 1;
   */
  studentName: string;
  /**
   * ID of the school the student is in
   *
   * @generated from protobuf field: string school_id = 2;
   */
  schoolId: string;
  /**
   * The current time
   *
   * @generated from protobuf field: google.protobuf.Timestamp current_time = 3;
   */
  currentTime?: Timestamp;
  /**
   * Optional time for when the homework ends after. Used to filter out homeworks that are no longer
   * relevant.
   *
   * @generated from protobuf field: google.protobuf.Timestamp homework_ends_after = 4;
   */
  homeworkEndsAfter?: Timestamp;
}
/**
 * @generated from protobuf message sparx.science.parentweb.v1.GetEmailSummaryResponse
 */
export interface GetEmailSummaryResponse {
  /**
   * List of packages for current homeworks assigned to the student.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.Package current_packages = 1;
   */
  currentPackages: Package[];
  /**
   * List of packages for the most recent homeworks assigned to the student. Where there are multiple
   * homeworks with the same, most recent due date, they are all returned.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.Package previous_packages = 2;
   */
  previousPackages: Package[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetSummaryRequest$Type extends MessageType<GetSummaryRequest> {
  constructor() {
    super('sparx.science.parentweb.v1.GetSummaryRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.parentweb.v1.GetSummaryRequest
 */
export const GetSummaryRequest = new GetSummaryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSummaryResponse$Type extends MessageType<GetSummaryResponse> {
  constructor() {
    super('sparx.science.parentweb.v1.GetSummaryResponse', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
      { no: 2, name: 'last_login', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.parentweb.v1.GetSummaryResponse
 */
export const GetSummaryResponse = new GetSummaryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEmailSummaryRequest$Type extends MessageType<GetEmailSummaryRequest> {
  constructor() {
    super('sparx.science.parentweb.v1.GetEmailSummaryRequest', [
      {
        no: 1,
        name: 'student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'current_time', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'homework_ends_after',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.parentweb.v1.GetEmailSummaryRequest
 */
export const GetEmailSummaryRequest = new GetEmailSummaryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEmailSummaryResponse$Type extends MessageType<GetEmailSummaryResponse> {
  constructor() {
    super('sparx.science.parentweb.v1.GetEmailSummaryResponse', [
      {
        no: 1,
        name: 'current_packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
      {
        no: 2,
        name: 'previous_packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.parentweb.v1.GetEmailSummaryResponse
 */
export const GetEmailSummaryResponse = new GetEmailSummaryResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.parentweb.v1.ParentWeb
 */
export const ParentWeb = new ServiceType(
  'sparx.science.parentweb.v1.ParentWeb',
  [
    {
      name: 'GetSummary',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'sw:parent',
          domain: 'sw',
        },
      },
      I: GetSummaryRequest,
      O: GetSummaryResponse,
    },
    {
      name: 'GetEmailSummary',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:parentemail',
          domain: 'schools',
        },
      },
      I: GetEmailSummaryRequest,
      O: GetEmailSummaryResponse,
    },
  ],
);
