import { Product } from '@sparx/api/apis/sparx/types/product';
import sparxMathsLogo from '@sparx/sparx-design/logos/sparx_maths_logo.svg';
import sparxReaderLogo from '@sparx/sparx-design/logos/sparx_reader_logo.svg';
import sparxScienceLogo from '@sparx/sparx-design/logos/sparx_science_logo.svg';
import sparxMathsHeader from 'images/headers/maths_header.svg';
import sparxReaderHeader from 'images/headers/reader_header.svg';
import sparxScienceHeader from 'images/headers/science_header.svg';

export const logoLookup: Record<Product, string> = {
  [Product.SPARX_MATHS]: sparxMathsLogo,
  [Product.SPARX_READER]: sparxReaderLogo,
  [Product.SPARX_SCIENCE]: sparxScienceLogo,

  [Product.SPARXMATHS_CURRICULUM]: '',
  [Product.SPARX_TEACHING]: '',
  [Product.SPARX_ASSESSMENTS]: '',
  [Product.PRODUCT_UNKNOWN]: '',
  [Product.SPARX_PRIMARY]: '',
};

export const headerLookup: Record<Product, string> = {
  [Product.SPARX_MATHS]: sparxMathsHeader,
  [Product.SPARX_READER]: sparxReaderHeader,
  [Product.SPARX_SCIENCE]: sparxScienceHeader,

  [Product.SPARXMATHS_CURRICULUM]: '',
  [Product.SPARX_TEACHING]: '',
  [Product.SPARX_ASSESSMENTS]: '',
  [Product.PRODUCT_UNKNOWN]: '',
  [Product.SPARX_PRIMARY]: '',
};

export const pathLookup: Record<Product, string> = {
  [Product.SPARX_MATHS]: '/maths',
  [Product.SPARX_READER]: '/reader',
  [Product.SPARX_SCIENCE]: '/science',

  [Product.SPARXMATHS_CURRICULUM]: '',
  [Product.SPARX_TEACHING]: '',
  [Product.SPARX_ASSESSMENTS]: '',
  [Product.PRODUCT_UNKNOWN]: '',
  [Product.SPARX_PRIMARY]: '',
};
