import { UserInteractionEventFields } from '@sparx/analytics';
import { Product } from '@sparx/api/apis/sparx/types/product';

const productToCategory: Record<Product, string> = {
  [Product.SPARX_MATHS]: 'maths-view',
  [Product.SPARX_READER]: 'reader-view',
  [Product.SPARX_SCIENCE]: 'science-view',
  [Product.SPARXMATHS_CURRICULUM]: '',
  [Product.SPARX_TEACHING]: '',
  [Product.SPARX_ASSESSMENTS]: '',
  [Product.PRODUCT_UNKNOWN]: '',
  [Product.SPARX_PRIMARY]: '',
};

export const clickedProductCard = (product: string): UserInteractionEventFields => ({
  category: 'home-view',
  action: `clicked ${product} card`,
});

export const clickedCurrentHomework = (
  product: Product,
  packageID: string,
): UserInteractionEventFields => ({
  category: productToCategory[product],
  action: 'clicked current homework',
  labels: {
    packageID,
  },
});

export const expandedCurrentHomework = (
  product: Product,
  expanded: boolean,
): UserInteractionEventFields => ({
  category: productToCategory[product],
  action: `previous homework ${expanded ? 'expanded' : 'collapsed'}`,
});

export const previousHomeworkClicked = (
  product: Product,
  packageID: string,
): UserInteractionEventFields => ({
  category: productToCategory[product],
  action: 'previous homework clicked',
  labels: {
    packageID,
  },
});

export const clickedMathsFAQs = (): UserInteractionEventFields => ({
  category: productToCategory[Product.SPARX_MATHS],
  action: 'clicked faqs',
});
export const clickedReaderFAQs = (): UserInteractionEventFields => ({
  category: productToCategory[Product.SPARX_READER],
  action: 'clicked faqs',
});

export const clickedMathsParentsGuide = (): UserInteractionEventFields => ({
  category: productToCategory[Product.SPARX_MATHS],
  action: 'clicked parents guide to Sparx Maths',
});

export const clickedReaderParentsGuide = (): UserInteractionEventFields => ({
  category: productToCategory[Product.SPARX_READER],
  action: 'clicked parents guide to Sparx Reader',
});

export const clickedMathsStudentLogin = (): UserInteractionEventFields => ({
  category: productToCategory[Product.SPARX_MATHS],
  action: 'clicked student login',
});

export const clickedReaderStudentLogin = (): UserInteractionEventFields => ({
  category: productToCategory[Product.SPARX_READER],
  action: 'clicked student login',
});

export const clickedScienceStudentLogin = (): UserInteractionEventFields => ({
  category: productToCategory[Product.SPARX_SCIENCE],
  action: 'clicked student login',
});

export const clickedPreviousBook = (bookTitle: string): UserInteractionEventFields => ({
  category: productToCategory[Product.SPARX_READER],
  action: 'clicked previous book',
  labels: { bookTitle },
});
export const clickedNextBook = (bookTitle: string): UserInteractionEventFields => ({
  category: productToCategory[Product.SPARX_READER],
  action: 'clicked next book',
  labels: { bookTitle },
});

export const clickedHeaderLogo = (from: string, to: string): UserInteractionEventFields => ({
  category: 'header',
  action: 'clicked logo',
  labels: {
    from,
    to,
  },
});

export const openedBookSummaryModal = (bookTitle: string): UserInteractionEventFields => ({
  category: productToCategory[Product.SPARX_READER],
  action: 'opened book summary modal',
  labels: {
    bookTitle,
  },
});
export const closedBookSummaryModal = (): UserInteractionEventFields => ({
  category: productToCategory[Product.SPARX_READER],
  action: 'closed book summary modal',
});

// Subscription events
export const unsubscribeClicked = (
  labels: Record<string, string | number | boolean>,
): UserInteractionEventFields => ({
  category: 'subscription',
  action: 'unsubscribe clicked',
  labels,
});
export const subscribeClicked = (): UserInteractionEventFields => ({
  category: 'subscription',
  action: 'subscribe clicked',
});
