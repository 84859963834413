// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/users/librarybooks/v1/librarybooks.proto" (package "sparx.reading.users.librarybooks.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { StudentBook } from '../../../content/v1/service';
import { Book } from '../../../books/v1/book';
import { MatureTheme } from '../../../books/v1/book';
import { SilverReader } from '../../../books/v1/book';
import { BookIdentifier } from '../../../books/v1/book';
import { BookImage } from '../../../books/v1/book';
import { BookPageCount } from '../../../books/v1/book';
/**
 * MetadataArbidged is a subset of the book metadata in sparx.reading.books.v1.Book.
 * You probably will not need to add a field here without also adding it there.
 *
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.MetadataAbridged
 */
export interface MetadataAbridged {
  /**
   * Resource name of the book. It is in the format `books/*`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Title of the book
   *
   * @generated from protobuf field: string title = 2;
   */
  title: string;
  /**
   * Page count information about this book.
   *
   * @generated from protobuf field: sparx.reading.books.v1.BookPageCount page_count = 3;
   */
  pageCount?: BookPageCount;
  /**
   * Cover image of the book.
   *
   * @generated from protobuf field: sparx.reading.books.v1.BookImage cover_image = 4;
   */
  coverImage?: BookImage;
  /**
   * The book's genres
   *
   * @generated from protobuf field: repeated string genres = 5;
   */
  genres: string[];
  /**
   * The book's authors
   *
   * @generated from protobuf field: repeated string authors = 6;
   */
  authors: string[];
  /**
   * The book's description
   *
   * @generated from protobuf field: string description = 7;
   */
  description: string;
  /**
   * Whether the book is an ebook
   *
   * @generated from protobuf field: bool ebook_active = 8;
   */
  ebookActive: boolean;
  /**
   * Whether the book is blacklisted
   *
   * @generated from protobuf field: bool blacklisted = 9;
   */
  blacklisted: boolean;
  /**
   * List of identifiers that uniquely identify this book. Note that this
   * can contain multiple of the same type of identifier.
   *
   * This field can also contain identifiers for the same book but with
   * different editions.
   *
   * @generated from protobuf field: repeated sparx.reading.books.v1.BookIdentifier identifiers = 10;
   */
  identifiers: BookIdentifier[];
  /**
   * If present then the book can be delivered in silver reader mode. The
   * message contains the configuration required to manage this.
   *
   * @generated from protobuf field: repeated sparx.reading.books.v1.SilverReader silver_reader = 11;
   */
  silverReader: SilverReader[];
  /**
   * List of mature themes in the book if any.
   *
   * @generated from protobuf field: repeated sparx.reading.books.v1.MatureTheme mature_themes = 12;
   */
  matureThemes: MatureTheme[];
}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.LibraryBook
 */
export interface LibraryBook {
  /**
   * Book metadata, e.g. title, authors, etc.
   *
   * @deprecated
   * @generated from protobuf field: sparx.reading.books.v1.Book metadata = 1 [deprecated = true];
   */
  metadata?: Book;
  /**
   * Student-specific information about the book, e.g. progress and ratings.
   *
   * @generated from protobuf field: sparx.reading.content.v1.StudentBook student_book = 2;
   */
  studentBook?: StudentBook;
  /**
   * An abridged version of the book metadata.
   *
   * @generated from protobuf field: sparx.reading.users.librarybooks.v1.MetadataAbridged metadata_abridged = 3;
   */
  metadataAbridged?: MetadataAbridged;
}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.ListLibraryBooksRequest
 */
export interface ListLibraryBooksRequest {
  /**
   * Optionally provide a user id to request the library books for another user.
   *
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.ListLibraryBooksResponse
 */
export interface ListLibraryBooksResponse {
  /**
   * The books in a user's library.
   *
   * @generated from protobuf field: repeated sparx.reading.users.librarybooks.v1.LibraryBook library_books = 1;
   */
  libraryBooks: LibraryBook[];
}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.ListNewBooksRequest
 */
export interface ListNewBooksRequest {
  /**
   * Optionally provide a user id to request the new books for another user.
   *
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.ListNewBooksResponse
 */
export interface ListNewBooksResponse {
  /**
   * The books available for the user to start.
   *
   * @deprecated
   * @generated from protobuf field: repeated sparx.reading.books.v1.Book books = 1 [deprecated = true];
   */
  books: Book[];
  /**
   * The books available for the user to start.
   *
   * @generated from protobuf field: repeated sparx.reading.users.librarybooks.v1.MetadataAbridged metadata_abridged = 2;
   */
  metadataAbridged: MetadataAbridged[];
}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.UpdateLibraryBookRequest
 */
export interface UpdateLibraryBookRequest {
  /**
   * The updated book
   *
   * @generated from protobuf field: sparx.reading.content.v1.StudentBook student_book = 1;
   */
  studentBook?: StudentBook;
  /**
   * The id of the current task. This is set when updating a book's rating.
   *
   * @generated from protobuf field: optional string task_id = 2;
   */
  taskId?: string;
  /**
   * The reason that the book was swapped. Only set when swapping the book.
   *
   * @generated from protobuf field: optional string swapped_reason = 3;
   */
  swappedReason?: string;
}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.UpdateLibraryBookResponse
 */
export interface UpdateLibraryBookResponse {
  /**
   * The updated book with its metadata.
   *
   * @generated from protobuf field: sparx.reading.users.librarybooks.v1.LibraryBook library_book = 1;
   */
  libraryBook?: LibraryBook;
}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.ListOnboardingLibraryBooksRequest
 */
export interface ListOnboardingLibraryBooksRequest {}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.ListOnboardingLibraryBooksResponse
 */
export interface ListOnboardingLibraryBooksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.users.librarybooks.v1.LibraryBook onboarding_library_books = 1;
   */
  onboardingLibraryBooks: LibraryBook[];
}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.BookChoiceConfig
 */
export interface BookChoiceConfig {
  /**
   * We seed the randomness with the week number, so this allows viewing book
   * choice for any week of the year.
   *
   * @generated from protobuf field: int32 week_number = 1;
   */
  weekNumber: number;
  /**
   * How many books to offer in the final selection.
   *
   * @generated from protobuf field: int32 number_of_books = 2;
   */
  numberOfBooks: number;
  /**
   * The offset to apply to the user's reading age. We will find books in a
   * range around this offset reading age. Also affected by latest rating.
   *
   * @generated from protobuf field: float offset_from_reading_age = 3;
   */
  offsetFromReadingAge: number;
  /**
   * In each iteration, how much above and below the starting value should we
   * consider when choosing books.
   *
   * @generated from protobuf field: float window_size = 4;
   */
  windowSize: number;
  /**
   * How many iterations we will consider before accepting a book with a higher
   * affinity.
   *
   * @generated from protobuf field: float anti_affinity_weight = 5;
   */
  antiAffinityWeight: number;
  /**
   * The maximum number of shared anti-affinity labels to allow in the book
   * selection.
   *
   * @generated from protobuf field: int32 max_affinity = 6;
   */
  maxAffinity: number;
  /**
   * Latest book rating.
   *
   * @generated from protobuf field: sparx.reading.users.librarybooks.v1.BookRating latest_book_rating = 7;
   */
  latestBookRating?: BookRating;
  /**
   * How much to offset the target reading age by when rating too hard / easy.
   *
   * @generated from protobuf field: float max_rating_offset = 8;
   */
  maxRatingOffset: number;
  /**
   * Denominator to divide `max_rating_offset` by to get the offset for ratings
   * of 2 or 4. E.g. if rated slightly easy (2) with `max_rating_offset = 0.5`,
   * `rating_offset_denominator = 2` means the target reading age would be
   * offset by +0.25 (0.5 / 2).
   *
   * @generated from protobuf field: float rating_offset_denominator = 9;
   */
  ratingOffsetDenominator: number;
  /**
   * A book is scarce if the number of two-week readers exceeds the max number
   * of licences multiplied by this number.
   *
   * @generated from protobuf field: float book_scarcity_factor = 10;
   */
  bookScarcityFactor: number;
  /**
   * The number of books to randomly pick from the top preference list.
   *
   * @generated from protobuf field: int32 choices_from_preferences_list = 11;
   */
  choicesFromPreferencesList: number;
}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.BookRating
 */
export interface BookRating {
  /**
   * The difficulty rating given by the user, 1 = much too easy, 5 = much too hard.
   *
   * @generated from protobuf field: int32 difficulty_rating = 1;
   */
  difficultyRating: number;
  /**
   * The reading age of the book that the rating was given on.
   *
   * @generated from protobuf field: float book_difficulty = 2;
   */
  bookDifficulty: number;
}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.ListBookChoiceRequest
 */
export interface ListBookChoiceRequest {
  /**
   * Optionally provide a user id to request the new books for another user.
   *
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * Optionally provide a user reading age override.
   *
   * @generated from protobuf field: float user_reading_age = 2;
   */
  userReadingAge: number;
  /**
   * Configuration for the book choice algorithm.
   *
   * @generated from protobuf field: sparx.reading.users.librarybooks.v1.BookChoiceConfig config = 3;
   */
  config?: BookChoiceConfig;
  /**
   * The version of the algorithm to use.
   *
   * @generated from protobuf field: sparx.reading.users.librarybooks.v1.ListBookChoiceRequest.AlgorithmVersion version = 4;
   */
  version: ListBookChoiceRequest_AlgorithmVersion;
  /**
   * Configuration to use in the first step of the preference score algorithm
   * version to select the top preferred books. This means we can trial e.g.
   * relaxing the anti-affinity rules or widening the initial reading age
   * window when selecting the top preferred books.
   *
   * @generated from protobuf field: sparx.reading.users.librarybooks.v1.BookChoiceConfig v3_config = 5;
   */
  v3Config?: BookChoiceConfig;
}
/**
 * @generated from protobuf enum sparx.reading.users.librarybooks.v1.ListBookChoiceRequest.AlgorithmVersion
 */
export enum ListBookChoiceRequest_AlgorithmVersion {
  /**
   * @generated from protobuf enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,
  /**
   * The algorithm that chooses books based on weekly random scores, trying
   * to avoid books with too high affinity to each other.
   *
   * @generated from protobuf enum value: ANTI_AFFINITY = 1;
   */
  ANTI_AFFINITY = 1,
  /**
   * The algorithm that chooses books based on preference scores generated by
   * the model.
   *
   * @generated from protobuf enum value: PREFERENCE_SCORES = 2;
   */
  PREFERENCE_SCORES = 2,
}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.ExcludedBook
 */
export interface ExcludedBook {
  /**
   * @generated from protobuf field: sparx.reading.books.v1.Book book = 1;
   */
  book?: Book;
  /**
   * @generated from protobuf field: repeated string reasons = 2;
   */
  reasons: string[];
}
/**
 * @generated from protobuf message sparx.reading.users.librarybooks.v1.ListBookChoiceResponse
 */
export interface ListBookChoiceResponse {
  /**
   * The books that would be offered to the user.
   *
   * @generated from protobuf field: repeated sparx.reading.books.v1.Book available_books = 1;
   */
  availableBooks: Book[];
  /**
   * Hard-filtered books.
   *
   * @generated from protobuf field: repeated sparx.reading.users.librarybooks.v1.ExcludedBook hard_filtered_books = 2;
   */
  hardFilteredBooks: ExcludedBook[];
  /**
   * Books that are in the right range but excluded due to affinity rules or
   * having a lower order in the weekly randomness.
   *
   * @generated from protobuf field: repeated sparx.reading.users.librarybooks.v1.ExcludedBook in_window_books = 3;
   */
  inWindowBooks: ExcludedBook[];
  /**
   * Not offered due to difficulty
   *
   * @generated from protobuf field: repeated sparx.reading.books.v1.Book out_of_window_books = 4;
   */
  outOfWindowBooks: Book[];
  /**
   * The reading age of the student used for book choice, or the override if
   * provided in the request.
   *
   * @generated from protobuf field: float user_book_reading_age = 5;
   */
  userBookReadingAge: number;
  /**
   * Map of book ID to the number of people who have read it within the last
   * two weeks.
   *
   * @generated from protobuf field: map<string, int64> last_two_week_readers = 6;
   */
  lastTwoWeekReaders: {
    [key: string]: number;
  };
  /**
   * Map of book ID to the preference score generated by the model that we have
   * saved in readingdb for the user.
   *
   * @generated from protobuf field: map<string, double> student_book_preference_scores = 7;
   */
  studentBookPreferenceScores: {
    [key: string]: number;
  };
}
// @generated message type with reflection information, may provide speed optimized methods
class MetadataAbridged$Type extends MessageType<MetadataAbridged> {
  constructor() {
    super('sparx.reading.users.librarybooks.v1.MetadataAbridged', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'page_count', kind: 'message', T: () => BookPageCount },
      { no: 4, name: 'cover_image', kind: 'message', T: () => BookImage },
      {
        no: 5,
        name: 'genres',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'authors',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 8, name: 'ebook_active', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 9, name: 'blacklisted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 10,
        name: 'identifiers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BookIdentifier,
      },
      {
        no: 11,
        name: 'silver_reader',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SilverReader,
      },
      {
        no: 12,
        name: 'mature_themes',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.reading.books.v1.MatureTheme',
          MatureTheme,
          'MATURE_THEME_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.MetadataAbridged
 */
export const MetadataAbridged = new MetadataAbridged$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LibraryBook$Type extends MessageType<LibraryBook> {
  constructor() {
    super('sparx.reading.users.librarybooks.v1.LibraryBook', [
      { no: 1, name: 'metadata', kind: 'message', T: () => Book },
      { no: 2, name: 'student_book', kind: 'message', T: () => StudentBook },
      {
        no: 3,
        name: 'metadata_abridged',
        kind: 'message',
        T: () => MetadataAbridged,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.LibraryBook
 */
export const LibraryBook = new LibraryBook$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLibraryBooksRequest$Type extends MessageType<ListLibraryBooksRequest> {
  constructor() {
    super('sparx.reading.users.librarybooks.v1.ListLibraryBooksRequest', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.ListLibraryBooksRequest
 */
export const ListLibraryBooksRequest = new ListLibraryBooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLibraryBooksResponse$Type extends MessageType<ListLibraryBooksResponse> {
  constructor() {
    super('sparx.reading.users.librarybooks.v1.ListLibraryBooksResponse', [
      {
        no: 1,
        name: 'library_books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LibraryBook,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.ListLibraryBooksResponse
 */
export const ListLibraryBooksResponse = new ListLibraryBooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNewBooksRequest$Type extends MessageType<ListNewBooksRequest> {
  constructor() {
    super('sparx.reading.users.librarybooks.v1.ListNewBooksRequest', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.ListNewBooksRequest
 */
export const ListNewBooksRequest = new ListNewBooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNewBooksResponse$Type extends MessageType<ListNewBooksResponse> {
  constructor() {
    super('sparx.reading.users.librarybooks.v1.ListNewBooksResponse', [
      {
        no: 1,
        name: 'books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Book,
      },
      {
        no: 2,
        name: 'metadata_abridged',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => MetadataAbridged,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.ListNewBooksResponse
 */
export const ListNewBooksResponse = new ListNewBooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLibraryBookRequest$Type extends MessageType<UpdateLibraryBookRequest> {
  constructor() {
    super('sparx.reading.users.librarybooks.v1.UpdateLibraryBookRequest', [
      { no: 1, name: 'student_book', kind: 'message', T: () => StudentBook },
      {
        no: 2,
        name: 'task_id',
        kind: 'scalar',
        opt: true,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'swapped_reason',
        kind: 'scalar',
        opt: true,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.UpdateLibraryBookRequest
 */
export const UpdateLibraryBookRequest = new UpdateLibraryBookRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLibraryBookResponse$Type extends MessageType<UpdateLibraryBookResponse> {
  constructor() {
    super('sparx.reading.users.librarybooks.v1.UpdateLibraryBookResponse', [
      { no: 1, name: 'library_book', kind: 'message', T: () => LibraryBook },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.UpdateLibraryBookResponse
 */
export const UpdateLibraryBookResponse = new UpdateLibraryBookResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOnboardingLibraryBooksRequest$Type extends MessageType<ListOnboardingLibraryBooksRequest> {
  constructor() {
    super(
      'sparx.reading.users.librarybooks.v1.ListOnboardingLibraryBooksRequest',
      [],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.ListOnboardingLibraryBooksRequest
 */
export const ListOnboardingLibraryBooksRequest =
  new ListOnboardingLibraryBooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOnboardingLibraryBooksResponse$Type extends MessageType<ListOnboardingLibraryBooksResponse> {
  constructor() {
    super(
      'sparx.reading.users.librarybooks.v1.ListOnboardingLibraryBooksResponse',
      [
        {
          no: 1,
          name: 'onboarding_library_books',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => LibraryBook,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.ListOnboardingLibraryBooksResponse
 */
export const ListOnboardingLibraryBooksResponse =
  new ListOnboardingLibraryBooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookChoiceConfig$Type extends MessageType<BookChoiceConfig> {
  constructor() {
    super('sparx.reading.users.librarybooks.v1.BookChoiceConfig', [
      { no: 1, name: 'week_number', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'number_of_books',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'offset_from_reading_age',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 4, name: 'window_size', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 5,
        name: 'anti_affinity_weight',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 6,
        name: 'max_affinity',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'latest_book_rating',
        kind: 'message',
        T: () => BookRating,
      },
      {
        no: 8,
        name: 'max_rating_offset',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 9,
        name: 'rating_offset_denominator',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 10,
        name: 'book_scarcity_factor',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 11,
        name: 'choices_from_preferences_list',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.BookChoiceConfig
 */
export const BookChoiceConfig = new BookChoiceConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookRating$Type extends MessageType<BookRating> {
  constructor() {
    super('sparx.reading.users.librarybooks.v1.BookRating', [
      {
        no: 1,
        name: 'difficulty_rating',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'book_difficulty',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.BookRating
 */
export const BookRating = new BookRating$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBookChoiceRequest$Type extends MessageType<ListBookChoiceRequest> {
  constructor() {
    super('sparx.reading.users.librarybooks.v1.ListBookChoiceRequest', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'user_reading_age',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 3, name: 'config', kind: 'message', T: () => BookChoiceConfig },
      {
        no: 4,
        name: 'version',
        kind: 'enum',
        T: () => [
          'sparx.reading.users.librarybooks.v1.ListBookChoiceRequest.AlgorithmVersion',
          ListBookChoiceRequest_AlgorithmVersion,
        ],
      },
      { no: 5, name: 'v3_config', kind: 'message', T: () => BookChoiceConfig },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.ListBookChoiceRequest
 */
export const ListBookChoiceRequest = new ListBookChoiceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExcludedBook$Type extends MessageType<ExcludedBook> {
  constructor() {
    super('sparx.reading.users.librarybooks.v1.ExcludedBook', [
      { no: 1, name: 'book', kind: 'message', T: () => Book },
      {
        no: 2,
        name: 'reasons',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.ExcludedBook
 */
export const ExcludedBook = new ExcludedBook$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBookChoiceResponse$Type extends MessageType<ListBookChoiceResponse> {
  constructor() {
    super('sparx.reading.users.librarybooks.v1.ListBookChoiceResponse', [
      {
        no: 1,
        name: 'available_books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Book,
      },
      {
        no: 2,
        name: 'hard_filtered_books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ExcludedBook,
      },
      {
        no: 3,
        name: 'in_window_books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ExcludedBook,
      },
      {
        no: 4,
        name: 'out_of_window_books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Book,
      },
      {
        no: 5,
        name: 'user_book_reading_age',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 6,
        name: 'last_two_week_readers',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: {
          kind: 'scalar',
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
      },
      {
        no: 7,
        name: 'student_book_preference_scores',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.librarybooks.v1.ListBookChoiceResponse
 */
export const ListBookChoiceResponse = new ListBookChoiceResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.users.librarybooks.v1.LibraryBooks
 */
export const LibraryBooks = new ServiceType(
  'sparx.reading.users.librarybooks.v1.LibraryBooks',
  [
    {
      name: 'ListNewBooks',
      options: {},
      I: ListNewBooksRequest,
      O: ListNewBooksResponse,
    },
    {
      name: 'ListLibraryBooks',
      options: {},
      I: ListLibraryBooksRequest,
      O: ListLibraryBooksResponse,
    },
    {
      name: 'UpdateLibraryBook',
      options: {},
      I: UpdateLibraryBookRequest,
      O: UpdateLibraryBookResponse,
    },
    {
      name: 'ListOnboardingLibraryBooks',
      options: {},
      I: ListOnboardingLibraryBooksRequest,
      O: ListOnboardingLibraryBooksResponse,
    },
    {
      name: 'ListBookChoice',
      options: {},
      I: ListBookChoiceRequest,
      O: ListBookChoiceResponse,
    },
  ],
);
